const coverDict = {
    name: "zdmc",
    placeholder: "tsxx",
    id: "sid",
    must: "sfbt",
    type: "txlx",
    width: "zdbl",
    bound: "zddm",
    dict: "zdbmc",
    length: "zdcd",
    json: "json",
    listId: "zdfl",
    glzdb: "glzdb",
    zdkxz: "zdkxz",
    sfxtzd: "sfxtzd",
    data: "data",
};

export const covertToSql = function (configList) {
    let newList = [];
    let idx = 0;
    configList.forEach((item) => {
        let obj = {};
        for (let k in coverDict) {
            let v = coverDict[k];
            obj[v] = item[k];
        }
        if (obj['txlx'] == "select" || obj['txlx'] == "selectEx") {
            if (obj['zddm']) {
                obj['json'] = obj['zddm']
                if(typeof(obj['json'])=="string"){
                    let split = obj['json'].split(",")
                    obj['zddm'] = split[0]
                }
                if(obj['json'] instanceof Array){
                    obj['zddm'] = obj['json'][0]
                    obj['json'] = obj['json'].join(",")
                }
            }
        } else if (obj['txlx'] == "local" || obj['txlx'] == "local1"){
        } else {
            obj['json'] = obj['zddm']
        }
        obj["wzxh"] = idx;
        newList.push(obj);
        idx++;
    });
    return newList;
};

export const covertToForm = function (configList) {
    let newList = [];
    let idx = 0;
    configList.forEach((item) => {
        let obj = {};
        for (let k in coverDict) {
            let v = coverDict[k];
            obj[k] = item[v];
        }
        obj["wzxh"] = idx;
        if (obj['type'] == "select" || obj['type'] == "local1" || obj['type'] == "selectEx"|| obj['type'] == "radio") {
            if (obj['json']) {
                obj['bound'] = obj['json'].split(",")
            }
        }
        newList.push(obj);
        idx++;
    });
    return newList;
};


export const covertToSqlSingle = function (config) {
    return covertToSql([config, ])[0]
};

export const covertToFormSingle = function (config) {
    return covertToForm([config, ])[0]
};